@font-face {
    font-family: Raleway-SemiBold;
    src: url(assets/fonts/Raleway-SemiBold.ttf);
   // font-weight: bold;
}
@font-face {
    font-family: Raleway-Bold; 
    src: url(assets/fonts/Raleway-Bold.otf),
    	 url(assets/fonts/Raleway-Bold.ttf);
   //font-weight: normal;
}
@font-face {
    font-family: Raleway-Light; 
    src: url(assets/fonts/Raleway-Light.ttf);
}
@font-face {
    font-family: Raleway-Regular;
    src: url(assets/fonts/Raleway-Regular.ttf);
   // font-weight: bold;
}
@font-face {
    font-family: Raleway-LightItalic;
    src: url(assets/fonts/Raleway-LightItalic.ttf);
   // font-weight: bold;
}
@font-face {
    font-family: Raleway-Italic;
    src: url(assets/fonts/Raleway-Italic.ttf);
}
@font-face {
    font-family: PlayfairDisplay-Regular;
    src: url(assets/fonts/PlayfairDisplay-Regular_0.otf);
   // font-weight: bold;
}
@font-face {
    font-family: PlayfairDisplay-Italic;
    src: url(assets/fonts/PlayfairDisplay-Italic_0.otf);
   // font-weight: bold;
}