$menu-height: 89px;
$section-bgcolor: #f7f7f7;

@mixin h2-text{ //service oldal
	margin:0;
	height:18.4vh;
	height:28.5vh;
	line-height: 30vh;
	text-align:center;
	font-family:PlayfairDisplay-Regular;
	font-size:2.92vw; //56px;
	color:#555555;
}

@function get-vw($target) {
  $vw-context: (1920 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}
//http://sassbreak.com/viewport-relative-headings-with-sass/
 
