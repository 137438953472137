body,html{
	background:white;
	font-family:Arial,sans-serif;
}

a:hover{
	text-decoration:none;
}

#wrapper{
    margin-left:0;
    margin-right:0;
    padding-left:0;
    padding-right:0;
	
	header{ 
		width:100%;
		position: relative;
 		
 		padding-top: 1px; //http://stackoverflow.com/questions/2890354/margin-top-in-a-nested-div
    	margin-top: -1px;

		.navbar{
			position: fixed;
			width:100%;
			margin-bottom:0px;
			border: 0; 
			height:  $menu-height;
			//background-color: coral;
			background-color: white;
			.container{
				width:95%;
				//background-color:lightgreen;
				padding-right:0;
				padding-left:0;

				.navbar-header{
					height:  $menu-height;

					.navbar-brand{ 
						 background: url(assets/img/new-logo.png) center center no-repeat;
  						 width: 420px;
						 height: 89px;

						/*width:300px;
						width:420px;
						height: 34px;
						height: 48px;
*/
			

						/*width:100%;	
  
						img{
							width:100%;
							max-width: 100%;
						}
	*/

					

					}
				/*	.logo{
						 display: block;
					    height: auto;
					    height: 48px;
					    width: 100%;
					    padding-top: 5px;
					    margin-right: 15px;
					    img{
					    	width:100%;
							max-width: 100%;
					    }
					}*/
				}

				.navbar-nav{

					margin-right:0;
					background: white;
					height: $menu-height;
					//background: yellow;

					& li{
					
						text-transform:uppercase;
					
						&:first-child a{
						
						margin-left:180px;
						margin-left:0px;

						}	

						& a{
							color:#6e6e6e;
							font-family: Raleway-SemiBold;	
							font-size:16px;
							line-height:16px;
							padding:0;
							margin-top:35px;
							margin-left:75px;

							&:hover{
								color:#545454;
							}
						
						}
						
					}
					

				}
			}
			
			//lista
			@media screen and (max-width:1439px){
				.container{ width:100%;}
				.container .navbar-nav li a { margin-left:30px; }
			}
			@media screen and (max-width:1145px){
	
				.container .navbar-nav li a { margin-left:20px; 
												font-size:14px;
												line-height:14px;
				}
			}
			@media screen and (max-width:1025px){
				.container .navbar-nav li a { margin-left:10px; 
												font-size:12px;
												line-height:12px;
				}
			}

			@media screen and (max-width:991px){ //menü 45 px-re
				
				height:50px;
				.container .navbar-header{ 
					height:50px; 
				}
				.container .navbar-nav{
					height:50px;
				}
				.container .navbar-nav li a { margin-top:20px; }

				.container .navbar-header .navbar-brand{
					    width: 220px;
					    height: 45px;
					    background-size: contain;
						margin-left: 25px;
					    //padding-left: 5px;
				}	
			}
			@media screen and (max-width:766px){ //mobil menü !!
			
				height:45px;

       			#navbar{ 
					width: 100%;
    				margin: 0;
    				padding: 0;
       			}			
				.container .navbar-header{ 
					height:45px; 
					button{
						margin-right:20px;
					}
					.navbar-brand{
						//margin-left:5px;
					}
				}
				.container .navbar-nav{
					height:auto;
					float:none;
					margin:0;
					li{
						text-align:center;
						padding:8px;
						padding:10px;
						a{
							margin-top:0;
							font-size:15px;
							line-height:15px;
						}
					}
				}

				.container .navbar-nav li:first-child a{ margin-left:10px; }
			}



		} //navbar


		#name-card-container{
			position: relative;
			//top:89px;
			margin-top:89px;
			user-select:none;
			
			height: calc(100vh - 89px);

		 		&>img{
			        //position: absolute;
			        top:0px;
			        left:0;
			        width:100%;
			        height:auto;
			        z-index:1;

			        object-fit: cover;
    				height: calc(100vh - 89px);
    				width: 100vw;
    				width: 100%;
		    	} 

		    	.name-card{
			    	width:100%;
			        height:360px;
			    	//height:(360/900)*100%;
			    	height:(360/900)*100%;
			    	background:black;
			    	background:transparent;
			        //position:relative;
			    	position:absolute;
			    	//top:200px;
			    	top:(288/900)*100%;
			    	top:(208/811)*100%;
			        bottom:0px;right:0px;left:0px;
					text-align:center;
					
					padding-top:2%;		

			        .name-card-op{
			    		position:absolute;
			    		top:0;
			    		left:0;
			    		width:100%;
			    		height:100%;
			    		background:black;
			    		z-index:2;
			    		opacity:0.5;
			    	}
					#name-card-logo{
						//height:145px;
						//height:auto;
						//width:100%;

						width:100%;
						max-width:300px;
						height:auto;

						z-index:10;
						position:relative;
						//background: red;
						background-image: url(../img/logo1.png);
    					background-position: center;
    					background-repeat: no-repeat;
					}
					&>img{
						//width:100%;
						//max-width:85px;
						//max-width:75px;
						//height:auto;
						
						height: 27%;
						position:relative;
						z-index:10;
					}

			    	h1,p{
			    		text-align:center;
			    		width:100%;
			    		color:white;
			    		z-index:10;
			    		position:relative;
			    		//opacity:1;
			    		//background: rgba(255, 255, 255, 0);
			    	}
			    	h1{
			    	   	margin-top:2.2%;
					   	font-family:PlayfairDisplay-Regular;
			    		font-size:64px;	//72
			    		font-size:3.5vw;	//72

			    	}
			    	p{
			    		font-family:Raleway-Regular;
			    		font-size:1.16vw; //24
			    		text-transform:uppercase;
			    		letter-spacing:2px;
			    	}
		    	}

		    @media screen and (max-width:991px){//kisebb menü

				margin-top:50px;
				.name-card{
					&>img{
						margin-top: 25px;
					}
					h1{
						font-size: 8.5vw;	
					}
					p{
						font-size: 3.16vw;
					}
				}	
			}	
		
		}
	}
}


// bemutatkozás
#intro{
	//padding-top:89px;
    //height:792px+89px;
    min-height:792px;
	width:100%;
	background:$section-bgcolor;
	.row{
		margin:0;
	}
	.intro-left{
		//background: lime;
		min-height: 791px;
		//max-height:791px;

		padding-top:115px;
		padding-right:70px;
		padding-right:4.3%;
		img{
			float:right;
			//width:100%;
		}
	}
	.intro-right{
		//min-height: 791px;
		padding-left:100px;
		padding-top:152px;
		.h2-text{
			text-align:left;
		}
		.focim{
			text-align:left;
			max-width:420px;
		}
		.intro-text{
			margin-top:45px;
			max-width: 47%;
			font-size:15px;
		}
		.section-link{
			text-align:left;
			margin-top:40px;
		}
	}
	@media screen and (max-width:991px){
		min-height:0;

		.intro-left{ 
			min-height:400px;
			min-height:250px;
			padding-top:15px;
			padding-top:45px;
			text-align: center;

			img{
				max-height:300px;
				max-height:235px;
				padding-left:86px;
				padding-left:0px;
				float:left;
				float: none; 
			}
		}

		.intro-right{
			padding-top:10px;
			padding-top:30px;
			padding-left:0px;
			.h2-text{font-size:24px;}
			.focim{ padding-bottom:15px; max-width:100%;} 
			.h2-text, .focim{ text-align: center; }
			.section-link{ margin-bottom:40px; text-align:center;}
			.intro-text{
				margin:0 auto;
				max-width: 80%;
			}
		}
	}

	@media screen and (max-width:1200px){
		
		.intro-right .intro-text{
			max-width: 75%;
		}
	}
}

#reference{

	margin-left:7.5px;
	margin-right:7.5px;
	
	.h2-text{
		padding-top:3%;
		margin-bottom:0px;
		@media screen and (max-width:991px){
		font-size: 24px;
		}
	}
	.focim{

	}
	.section-link{
		margin-top: 2%;
    	margin-bottom: 3%;
	}

	.row{
		margin:0;
		margin-top:15px;
		.ref-box{

			padding-right:7.5px;
			padding-left:7.5px;
			
			.ref-box-img{
				width:100%;
				height:auto;
				position:relative;

				img{
					width:100%;
					position:relative;
				}
				.ref-box-hover{
					position:absolute;
					top:15px;
					left:15px;
					bottom:15px;
					right:15px;
					background-color:white;
					opacity: 0.85;
					display:none;
				}
				.ref-box-text{
					position:absolute;
					top:15px;
					left:15px;
					bottom:15px;
					right:15px;
					background-color:transparent;
					display: none;
					//padding-top:69px;
					text-align:center;

					.ref-box-type{
						font-family: Raleway-Regular;
						font-size:13px;
						
						color:#696969;
						text-transform:uppercase;
						margin-top: 22%;
					}
					.ref-box-title{
						font-family:PlayfairDisplay-Regular;
						font-size:36px;
						font-size:1.88vw;
						color:#555555;
						letter-spacing: 0px;
						display: block;
						margin-top:0;
					}
					a{
						font-family:Raleway-Bold;
						font-size:12px;
						font-size:0.63vw;
						letter-spacing: 2px;
						text-transform:uppercase;
						color:#555555;
						display:block;
						margin-top:35px;
					}
					@media (max-width: 992px){
						.ref-box-type{
							font-size:26px;
						}
						.ref-box-title{
							font-size:6vw;
						}
						a{
							font-size:2.5vw;
						}

					}

				}
			}
			&:hover .ref-box-hover{ display: block;}
			&:hover .ref-box-text{ display: block;}
		}
	}
	.row:last-child{ margin-bottom:15px;}
	@media screen and (max-width:991px){
		h1{
		    height: 20.5vh;	
		    line-height: 18vh;
		    font-size: 6.92vw;
		}
	}
}
#services{
	width:100%;
	background: #f7f7f7;
	text-align:center;
	padding-bottom:3%; //8
	
	
	.h2-text{
		padding-top:5%;
		@media screen and (max-width:991px){
		font-size: 24px;
		}
	}
	.section-link{
		padding-top:3%;
		padding-bottom:6%;
	}
	.service-row{
		width:60%;/*background: red;*/
		margin:0 auto;
		@media screen and (max-width:1330px){
			width:100%;
		}
	}
	.service-step{
		padding:0;
	}
	.service-step-num{
		//width:22,1%;
		width:25.1%;
		height:100%;
		//background: red;
		float:left;
		line-height: 0.8;
		font-size: 83px;
		//font-size: 5vw;
		font-family: PlayfairDisplay-Italic;
		color: rgb(214, 214, 214);
	}
	.service-step-text{
		float:left;
		//width:78%;
		width:74.9%;
		padding-left:5%;
		padding-left:0;
		padding-right:5%;
		//background:lime;
		h3{
			text-align: left;
			text-transform: uppercase;
			font-family:Raleway-Bold;
			font-size: 16px;
			//color: rgb(85, 85, 85);
			color:#555555;
  			line-height: 1.313;	
  			letter-spacing:1px;
		}
		p{
			text-align: left;
			font-family:Raleway-Regular;
			font-weight:normal;
			font-size:14px;
			color:#555555;
		}
	}
}

#news{
	background:white;
	text-align:center;
	margin-bottom:145px;
	margin-bottom:7.5%;
	color:#555555;	

	h1{ //news oldalon
		height: 20vw;
		line-height: 18vw;
		@media screen and (max-width:991px){
			height:12vw;
			font-size:5.92vw;
		}
	}

	.h2-text{
		margin-top: 5%;
		@media screen and (max-width:991px){
		font-size: 24px;
		}
	}
	.section-link{
		margin-top:1.5%;
		margin-bottom:5%;
	}

	.news-content{
		width:60%;
		margin:0 auto;
		//background-color: red;
		@media screen and (max-width:1330px){
			width:80%;

			.col-md-4{
				margin-top:60px;
			}
		}
		@media screen and (max-width:991px){
			.col-md-4{
				margin-top:30px;
			}	
		}
		h3{
			font-family:PlayfairDisplay-Regular;
			font-size:21px;
			//font-weight:bold;
			text-align:left;
			color:#555555;
			@media screen and (max-width:991px){
			text-align:center;
			}	
		}
		time{
			display:block;
			text-align:left;
			font:{
				family: Raleway-Regular;
				size: 12px;
			}
			@media screen and (max-width:991px){
			text-align:center;
			}
		}
		p{
			font:{
				family: Raleway-Regular;
				size: 15px;
			}
			color:#555555;	
			margin-top:8%;
			text-align:justify;
			@media screen and (max-width:991px){
			margin:3% auto;
			max-width:360px;
			}

		}
	}
	a img{
		max-width: 360px;
		width:100%;
	}
	
}

#quote{
	height: 424px;
	width: 100%;
	position:relative;
	text-align:center;
	color:white;
	
    //background-image: url(../img/idezet-bg.jpg);
	background-image: url(assets/img/idezet-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;

	.quote-op{
		background-color:black;
		position:absolute;
		top:0;left:0;
		width:100%;
		height: 100%;
		opacity:0.5;
		z-index:1;
	}
	div.focim{
		font-size:2.5vw; //42px
		color:white;
		padding-top:6%; //120px
		position:relative;
		z-index:10; 
		width:55%;
		margin:0 auto;
		@media screen and (max-width:1230px){
			padding-top:12%;
			font-size:3vw;
		}
		//@media screen and (max-width:815px){
		@media screen and (max-width:600px){
			padding-top:15%;
			font-size:4vw;
			font-size:7vw;
			width:75%;
		}
	}
	div:last-child{
		font-family:Raleway-Regular;
		font-size:16px;
		text-transform:uppercase;
		margin-top:70px;
		position:relative;
		z-index:10; 
	}
}

footer{
	width:100%; 
	background: #efefef;
	text-align:center;
	color:#555555;

	img{
		margin-top:50px;
	}
	.h2-text{
		margin-top:42px;
		text-transform:none;
		font-size:16px;
	}
	.job{
		font:{
			family:Raleway-LightItalic;
			size: 14px;
		}
		letter-spacing:1px;
		padding-bottom:20px;
	}
	.dzsem-info{
		font:{
			family: Raleway-Regular;
			size:14px;
		}
		a{
			color:#555555;
			&:hover{
				color:#555555;
			}
		}
	}
	.social-icons{
		margin-top: 45px;
		a{
			color: #555555;
			height:30px;
			width:30px;
			padding-left:10px;
			padding-right:10px;
			display: inline-block;
		}
	}
	.copy-text{
			margin-top: 36px;
			padding-bottom: 30px;
			font:{
				family:Raleway-Light;
				size:13px;
			}
	}
}


//általános
.h2-text{
	font-family:Raleway-Regular;
	font-size:14px;
	color:#696969;
	text-transform:uppercase;
	letter-spacing:1px;
	text-align:center;

}
.focim{
	font-family:PlayfairDisplay-Regular !important;
	font-size:56px;
	font-size:3.13vw;
	color:#555;
	text-align:center;
}
.intro-text{
	font-family:Raleway-Regular;
	font-size:16px;
	color:#696969;
	text-align:justify;
}
.section-link{ 
	font-family:Raleway-Bold;
	font-size:10px;
	letter-spacing:2px;
	text-transform:uppercase;
	text-align:center;
	color:#555555; 
	display:block;
	&:hover{ text-decoration:none;color:#555555;}
}
.focim-page{
	margin-top:89px;

	h1{
		@include h2-text;
	}
}

// aloldalak
#head{
	position: relative;
	margin-top:89px;
	user-select:none;

	
	height: calc(100vh - 89px);

	&>img{
        top:0px;
        left:0;
        //width:100%;
        //height:auto;
        z-index:1;
		position: relative;	

		object-fit: cover;
    	height: calc(100vh - 89px);
    	width: 100vw;
    	width: 100%;

	}
	.head-op{
		top:0;left:0;bottom:0;right:0;
		position: absolute;
		background-color: black;
		opacity:0.3;
		z-index:2;
	}
	&>h1{
		margin: 0;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);	
		z-index:100;
		font:{
			family:PlayfairDisplay-Regular;
			size:72px;
			size:3.75vw;
		}
		color:white;
		@media screen and (max-width:991px){
			font-size:8.75vw;
		}	
	}
	@media screen and (max-width:991px){//kisebb menü

		margin-top:50px;
	}
}


#service{
	//background-color: red;
	//height:300px;
	
	font-family:Raleway-Regular;
	font-weight:normal;

	h2{
		@include h2-text;
		@media screen and (max-width:991px){
		font-size: 24px;
		}
	}

	background-color: #f7f7f7;
	.row{
		margin-left:0;
		margin-right:0;

		margin-top:78px;
		@media screen and (max-width:991px){
			margin-top: 0px;
		}
	}
	div:first-of-type{
		margin-top:0px;
	}
	//.row:last-of-type div{
	
	//&>div:last-child>div:first-child {
	
	.ser-img-left{
		padding-right:15px;
		padding-top:42px;
		text-align: right;
	}
	.ser-img-right{
		padding-left:15px;
		padding-top:42px;
		text-align: left;
	}

	.serv-img-container{
	//div:last-of-type {
		
		//background-color: red;
		
		img{
			width:70%;
			width:58%;
		}
		@media screen and (max-width:991px){
			text-align: center;
		}	
	}
	
	.serv-text-container{
		//background-color:lime;
		h3{
			width:70%;
			width:58%;
			padding-top:68px;
			padding-top:45px;
			padding-bottom:50px;
			margin:0;
			font-family:PlayfairDisplay-Regular;
			font-size:30px;
			color:#696969;

		}
		p{
			width:70%;
			width:58%;
			text-align:justify;
			margin-bottom: 0;
			font-family:Raleway-Regular;
			font-size:16px;
			color:#696969;
		}
			 
	}
	.serv-text-left{
		h3{
			float:right;
			width:62%;	
			width:58%;	
		}
		p{
			float:right;
		}
	}

	.serv-text-container{

		@media screen and (max-width:991px){
			//text-align: center;
			h3{
				width:70%;
				display: block;
				margin:0 auto;
				float:none;
				width:80%;
			}
			
			p{
				display: block;
				margin:0 auto;
				float:none;
				width:80%;
			}
		}
	}

	@media screen and (max-width:991px){
		.reverse-row{
			float:right;
		}
		.reverse-elem{
			float:left;
		}
		h2{
			height: 22.5vh;
    		line-height: 28vh;

    		height: 6.5vh;
    		line-height: 12vh;
 			
 			font-size: 6.92vw;
		}
		.serv-text-container h3{
			//width: 58%;
			font-size: 25px;  
			font-size: 19px;  

			padding-top: 25px;
    		padding-bottom: 25px;  	
		}
	}

	.service-list{

		padding-bottom:100px; //210
		background-color: #f7f7f7;
		div{
			padding-left:0;
			//padding-right:0;

			&>ul{

				width:70%;
				width:58%;
				padding-left:20px;
				color:#696969;
				&>li{
					font-family:Raleway-SemiBold;
					font-size:16px;
					padding-left:20px;
					margin-top:25px;
					&:first-child{ margin-top:0;}	
					span{
						font-family: Raleway-Regular;
					}
					&>ul{
						padding-left:0px;
						margin-top:4px;
						&>li{
							font-family: Raleway-Regular;
							font-size:16px;
							text-align:justify;
							padding-left:0px;
							list-style-type:none;
						}
					}
					
				}
				@media screen and (max-width:991px){
					width:80%;
				}

			}
			
		}
	
		div:first-child{
			&>ul{
				float:right;
				@media screen and (max-width:991px){
					float:none;
					clear:both;
					margin:30px auto;
				}
			}
		}
		div:last-child{
			padding-left:15px;
			&>ul{
				@media screen and (max-width:991px){
					margin:30px auto;
				}
			}
		}

		@media screen and (max-width:991px){
			padding-bottom:10px;
		}

	}

	.calc{
		margin-top:0;
		color:#696969;
		background-color:white;
		font-family:Raleway-Regular;
		
		h2{
			@include h2-text;
			//line-height:10vh;
			@media screen and (max-width:991px){
				height: 11.5vh;
    			line-height: 13vh;
    			font-size: 6.92vw;
			}
		}

		.calc-text-container{
			width:70%;
			width:58%;
			float:right;
			//background-color: lime;
			h3{
				font-family:Raleway-SemiBold;
				font-size:16px;
				margin:8px 0;
			}
			ol,ul{
				padding-left:18px;
				li{
					font-family:Raleway-Regular;
					font-size:16px;
					margin-top:35px;
					margin-top:1.8vh;
				}
			}
			p{
				font-family:Raleway-Regular;
				font-size:16px;
				margin-top:35px;
				margin-top:2vh;
			}
			@media screen and (max-width:991px){
					float:none;
					clear:both;
					width:85%;
					margin: 0 auto;
			}
		}
		
		&>div:first-of-type{ 
			//background-color: red;
			//padding-right:0;
		}
		&>div:last-of-type{ 
			//background-color:skyblue;
			padding-left:30px;
			img{
				width:58%;
				@media screen and (max-width:991px){
					margin:0 auto;
					display:block;
				}
			}
		}

		
	}

	
} //#service oldal end

#service + #reference{
		//background-color: red;
		background-color:white;
		&>.focim{    
			margin-top: 0px;
    		padding-top: 65px;
			@media screen and (max-width:991px){
				    font-size: 6.13vw;
			}    
    	}
} //#service oldalhoz


#about{

	.row{
		width:59.3%;
		margin:0 auto;	
		.about-left{
			//background-color: red;
			padding-left:0;
			padding-right:0;
			figure{
				padding-top:135px;
				//text-align:center;
				& > img{
					max-width:86%;
				}
				figcaption{
					margin-top:36px;
					max-width:86%;
					text-align:center;
					div{
		    			font:{
		    				family:Raleway-SemiBold;
		    				size:16px;
		    			}
    					color:#696969;
    				}
    				div:last-child{
    					color:#696969;
						font-family: Raleway-LightItalic;	
    				}
				}
			}
		}	
		.about-right{
			//background-color: slategray;
			
			padding-right:0px;
			padding-top:135px;
			padding-bottom:200px;
			
			h2{
				text-align:left;
				margin:0 0 26px 0;
			}
			p{
				text-align:justify;
				margin-top:34px;
				font:{
					family:Raleway-Regular;
					size:16px;
    			}	
			}
			h3{
				margin:54px 0 25px 0;
				font:{
					family:Raleway-SemiBold;
					size:16px;
    			}
			}
			ul{
				padding-left: 15px;	
				li{
					padding-left:30px;
					padding-top:2px;
					
					font:{
						family:Raleway-Regular;
						size:16px;
    				}
    				a{
    					color:black;
    					font:{
							family:Raleway-SemiBold;
							size:16px;
		    			}
    				}
    				img{
    					max-width:200px;
    					
    				}

				}

			}
			.dzs-li-img{
				list-style-type: none;
			}
			.dzs-modal-link{
				//color:red;
				a{
					
					font:{
					//	family:Raleway-Regular;
						weight:normal;
					}
				}
			}
		}
	}	

	@media screen and (max-width:991px){
		.row{
			width:80%;
			.about-left{
				figure{
					text-align: center;
					padding-top:30px;
				}
			}
			.about-right{

				padding-top:30px;
				padding-bottom:30px;
				h2{
					font-size:4.6vw;
				}
			}
		}
	}
}


.reference-page{
	//	background-color:white;
	
	&>h1,h2,p,h3,h4,h5,h6
	{
		width:49%;
		margin:0 auto;
	}
	h2{
		margin-top:170px;
		margin-top:70px;
		font-family:PlayfairDisplay-Regular-Regular;
		font-size:30px;
		color:#696969;
	}
	&>h1,h3,h4,h5,h6{
		font-family:PlayfairDisplay-Regular-Regular;
		color:#696969;
	}
	p{
		margin-top:35px;
		@extend .intro-text;
	}
	.focim{	
		margin-top:140px;
		margin-bottom:110px;
	}
	@media screen and (max-width:991px){
		.focim{	
			margin-top:70px;
			margin-top:25px;
			margin-bottom:70px;
			margin-bottom:25px;
			font-size: 8.13vw;
		}
		&>h1,h2,p,h3,h4,h5,h6{
			width:80%; 
			margin-top:40px;
		}	
	}
}

//galléria
.dzs-gallery{
	
	padding-left:7.5px;
	padding-right:7.5px;
	background-color: #f7f7f7;

	.row{
		margin:0;
		padding-top:15px;
		.ref-box{

			padding-right:7.5px;
			padding-left:7.5px;
			
			.ref-box-img{
				width:100%;
				height:auto;
				position:relative;

				img{
					width:100%;
					position:relative;
				}
				.ref-box-hover{
					position:absolute;
					top:0px;
					left:0px;
					bottom:0px;
					right:0px;
					background-color:black;
					opacity: 0.5;
					display:none;
					
				}
				
				.ref-box-text{
					position:absolute;
					top:0px;
					left:0px;
					bottom:0px;
					right:0px;
					background-color:transparent;
					//background-color:red;
					display: none;
					text-align:center;
					
					a{
						//font-family:Raleway-Bold;
						font-size:12px;
						font-size:2.5vw;
						color:white;
						display:block;
						width:100%;
						height:100%;

						i{
						margin: 0;
					    position: absolute;
					    top: 50%;
					    left: 50%;
					    transform: translate(-50%, -50%);
						}
					}

					@media (max-width: 992px){

						a{
							font-size:9.5vw;
						}

					}

				}
			}
			&:hover .ref-box-hover{ display: block;}
			&:hover .ref-box-text{ display: block;}
		}
	}
	//.row:last-of-type{ padding-bottom:15px;}
	
}

.ref-links{
	background-color: #f7f7f7;
	padding-top:210px;
	padding-bottom:89px;
	.row{
		width:59%;
		margin:0 auto;
		//background-color: slategray;
	
		div:nth-of-type(1){
			text-align:left;
		}

		div:nth-of-type(2){
			text-align:center;
		}
		div:last-of-type{
			text-align:right;
		}
		div a,
        div span
        {
			font-family:Raleway-Bold;
			font-size:1.2vh;
			color:#555;
			text-transform:uppercase;
			letter-spacing:2px;

		}
		div{
			padding-left:0;
			padding-right:0;
			
			
		}

	}

	@media (max-width: 992px){
		padding-top:10px;
		padding-bottom:10px;		
					
		//.row div{text-align:center;}		
		.row .link-container{text-align:center !important; padding: 10px 0;}
		.row{
			width:80%;
			div a,
        	div span
        	{
        		font-size:2.7vh;  
        	}
		}		
	}
}
//hírek oldal
.news-page{
	margin-bottom:0px !important;

	.row{
		padding-bottom:100px;
	}
	
	.ref-links{
			padding-top:50px;
			padding-bottom:100px;
			background-color:white;
			.row{
				padding-bottom:2px;	
				div a,
                div span{
					font-size: 2vh;
				}
				div:nth-of-type(2){
					a{
						font-family: Raleway-Regular;
					}
					.actual-link,
                    .current
                    {
						font-family: Raleway-Bold;
					}
				}	
			}
		}
	
	@media (max-width: 992px){
		.row{
		padding-bottom:5px;
		}
		.ref-links{
			padding-top:5px;
			padding-bottom:5px;
		}
	}

}

#post{

	color:#696969;

	.row{
		width:49%;
		//background-color: ivory;
		margin:0 auto;
		h1{
		 margin-top:160px;
		 margin-bottom: 25px;
		 height:45px;
		 line-height: 45px;
		 display:inline-block;
		 width:100%;
		}
		time{
			text-align: center;
			margin-bottom:130px;
			display: block;
			color:#696969;
			font:{
				family:Raleway-Regular;
				size:16px;
			} 

		}
		img{
			width:100%;
			max-width: 942px;
		}
		h2{
			color:#696969;
			font:{
				family:PlayfairDisplay-Regular;
				//size:30px;
				size:3.5vh;
			}
			//margin:54px 0;
			margin:8% 0;
		}
		p{
			margin-top: 35px;
			text-align: justify;
			font:{
				family:Raleway-Regular;
				size:16px;
				}
		}
		h3{
			margin-top:69px;
			margin-bottom: 0;
			font-family: Raleway-Bold;
			font-size:2.3vh;
		}

		.post-ext{
			width:100%;
			margin:14% 0;
			div{
			padding-left:0;
			padding-right:0;
			}
			.tags{
				//background-color:LavenderBlush;
				 font-family: Raleway-SemiBold;
				 color:#555;
				 font-size:16px;
				 a,span{
				 	 font-family: Raleway-Regular;
				 	 color:#555;
				 	padding-left:5px;
				 	padding-right:5px;
				 }

			}
			.sharing{
				text-align: right;
				font-family: Raleway-SemiBold;
				color:#555;
				font-size:16px;

				a{
					color:#555;
					padding:0 4px;
				}
			}
		}
		.back-link{
			margin:9% 0;
			text-align: center;
			display: block;
			font-family: Raleway-Bold;
			font-size:1.8vh;
			text-transform: uppercase;
			color:#555;
			letter-spacing: 2px;
		}
	}
	@media (max-width: 992px){
		.row{
		width:95%;
			h1{ margin-top:0; margin-bottom:10px; font-size:5vh;}
			time{ margin-bottom:10px;}
		}
	}
}

#partnerek{
	
	color:#696969;
	
	h1{
		height:43vh;
		line-height: 37vh;
	}

	.row{
		width:49%;
		//background-color: peru;
		margin: 0 auto;
		padding-bottom:130px;
		div:first-child{
			//background-color: red;
			img{
				width:100%;
				max-width:458px; 
			}
		}
		.partnerek-desc{
			padding-left:78px;
			padding-right: 0px;
			//background-color: Cornsilk;
			h2{
				font:{
					family:PlayfairDisplay-Regular;
					//size:30px;
					size:3.5vh;
				}	
			}
			h3{
				font-family: Raleway-SemiBold;
				font-size:1.8vh;
				margin-bottom: 0px;
				margin-top:14%;	
			}
			p{
				font-family: Raleway-Italic;
				font-size:1.8vh;
				margin-bottom: 0px;
			
			}
			a{
				font-family: Raleway-Regular;
				font-size:1.8vh;
				color:#696969;	
			}
		}
	}

	@media (max-width: 992px){
	
		h1{
			height:33vh;
			height:15vh;
			line-height:27vh;
			line-height:7vh;
			font-size: 5.92vw;
		}
		.row{ 
			width:75%; 
			padding-bottom:10px;

			div:first-child{
				text-align: center;
			}
			.partnerek-desc{
				padding-left:4%;
				h3{
					margin-top: 2%;
				}
			}
		}
	}
		
}

#contact{
	
	color:#696969;

	h1{
		height:43vh;
		line-height: 37vh;
		color:#555;
	}

	.row{
		width:59%;
		//background-color: ivory;
		margin: 0 auto;
		padding-bottom:175px;
		div:first-child{
			//background-color: red;
			padding-left:4.5%;
			padding-right:10%;
			img{
				width:100%;
				max-width:407px; 
			}
		}
		.contact-desc{
			padding-left:9.8%;
			padding-top:4.6%;
			padding-right:0;
			h2{
				font:{
					family:PlayfairDisplay-Regular;
					//size:30px;
					size:3.5vh;
				}
				margin-top: 0;
				margin-bottom: 0;	
			}
			h3{
				font-family: Raleway-SemiBold;
				font-size:1.8vh;
				margin-bottom: 0px;
				margin-top:11%;	
			}
			p{
			  font-family: Raleway-Italic;
				font-size:1.8vh;
				margin-bottom:11%;
				margin-top:0px;	
			}
			.contact-info{
				margin-top:13.1%;
				font-family: Raleway-Regular;
				font-size:1.8vh;
				color:#696969;

				.info-type{

					float:left;
					padding-left:0;
					padding-right:0;
					width:14%;
					//background-color: lightyellow;
					line-height: 40px;
				}
				.info-content{
					padding-left:5px;
					width:85%;
					float:left;
					line-height: 40px;
					a{
						color:#696969;
					}
				}
			}

		}

	}

	@media (max-width: 992px){
	

		h1{
			height:33vh;
			height:16vh;
			height:10vh;
			line-height:27vh;
			line-height:10vh;
			line-height:4vh;
			font-size: 5.92vw;
		}
		.row{
			padding-bottom:10px;
			width:70%;
			div:first-child{
				text-align: center;
				padding-left:0;
				padding-right:0;
				img{
					padding-left:2px;
					padding-right:2px;
				}
			}
			.contact-desc{
				padding-left:1%;

				.contact-info{
					margin-top:9.1%;
					font-size: 2vh;
					.info-type{
						width:17%;
						line-height: 30px;
						text-align: left;
					}
					.info-content{
						width:82%;
						line-height: 30px;
					}
				}
			}
		}
					
	}	
}