.modal{

	margin-top:30px;

	.modal-header{
		border-bottom:0px;
	}
	.modal-body{

		h1{
			font-size:16px;
			font-weight:bolder;
			margin:25px 0;
			line-height:30px;	
		}

		h1,h2{

			text-align:center;

			
		}
		img{
			width:100%;
		}
		.center-img{
			display:block;
			margin:0 auto;
		}
	}
}

#arcModal{

	//min-width:740px;

	.modal-body{

		h1{
			font-size:16px;
			font-weight:bolder;
			margin:25px 0;
		}
		p{
			line-height:30px;
		}
	}

}

#designModal{

	//min-width:740px;

	.modal-body{

		h1{
			font-size:16px;
			font-weight:bolder;
			margin:25px 0;
			line-height:30px;
		}
		p{
			line-height:20px;
			//font-size:11px;
			text-align:center;
		}
		h2{
			font-size:14px;
			font-weight:bolder;
		}
	}

}
#kepzeletModal{

	//min-width:740px;

	.modal-body{

		h1{
			font-size:16px;
			font-weight:bolder;
			margin:25px 0;
			line-height:30px;
		}
		p{
			line-height:20px;
			//font-size:11px;
			text-align:center;
		}
		h2{
			font-size:14px;
			font-weight:bolder;
			margin-bottom:50px;
		}
	}

	.italic-text{
		font-style:italic;
	}
	.bold-text{
		font-weight:bold;
	}
	.ul-text{
		text-decoration:underline;
	}
}

#trilakModal{

	@media (min-width: 900px){
		.modal-dialog {
			width: 800px;	
		}
	}		
}
#loszModal{
	@media (min-width: 900px){
		.modal-dialog {
			width: 800px;	
		}
	}
}
#bnvModal{
	@media (min-width: 900px){
		.modal-dialog {
			width: 800px;	
		}
	}
}